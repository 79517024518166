import Swiper from 'swiper';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', () => {
  const trustElement = document.querySelector('.trust-content');
  if (trustElement) {
    new Swiper('.trust-content', {
      // Swiper options
      direction: 'horizontal', // Horizontal scrolling
      loop: true, // Infinite loop
      spaceBetween: 20, // Space between slides
      slidesPerView: 'auto', // Allow auto adjustment of slide widths
      speed: 2000, // Speed of continuous scrolling
      modules: [Autoplay],
      autoplay: {
        delay: 0, // Continuous scrolling
        disableOnInteraction: false, // Keeps scrolling after user interaction
      },
    });
  } else {
    console.error("Element with class '.trust-content' not found.");
  }
});